import React from 'react';
import Button from '../atoms/Button';
import Partner from '../../assets/images/team/naif.png';
import Partner2 from '../../assets/images/team/abdulrahman.png';
import Partner3 from '../../assets/images/team/piotr.png';
import { useTranslation, Trans } from 'react-i18next';

const LearMore = ({ id = '' }) => {
    const { t } = useTranslation();
    return (
        <div id={id} className='container mx-auto py-16 px-0 md:px-20'>
            <p className='font-bold md:text-5xl text-[2rem] py-10'>
                <Trans t={t} shouldUnescape i18nKey="LearnMore">
                    <span className='text-[#00807F]'></span>
                </Trans>
            </p>

            <div className='grid grid-cols-1 md:grid-cols-3 gap-5 py-10 '>
                <div className='col-span-2 w-full px-4 sm:px-0 md:px-0'>
                    <p className='text-lg text-justify  sm:text-center md:text-left text-[#8E9AA6]'>
                        {t("opusDescription")}
                    </p>
                </div>
                <div className='grid place-content-center h-full'>
                    <Button title='View All' className='bg-darkBlue text-white'></Button>
                </div>
            </div>

            <div className='grid px-2 grid-cols-1 md:grid-cols-3 gap-10 '>
                <div className='relative rounded-lg overflow-hidden'>
                    <img className='w-full h-[500px] object-cover' src={Partner} alt='Cardbackgroundimage'></img>
                    <div className='absolute inset-0 bg-gradient-to-t from-[#0D6D86] to-transparent opacity-40'></div>
                    <div className='absolute inset-0 flex items-end justify-left'>
                        <div className='text-left p-5'>
                            <p className='text-[16px] text-white'>{t("Naif Alwehaiby")}</p>
                            <p className='text-[16px] text-white'>{t("Founder & CEO")}</p>
                        </div>
                    </div>
                </div>

                <div className='relative rounded-lg  overflow-hidden'>
                    <img
                        className='w-full h-[500px] object-cover'
                        src={Partner2}
                        alt='Cardbackgroundimage'></img>
                    <div className='absolute inset-0 bg-gradient-to-t from-[#0D6D86] to-transparent opacity-40'></div>
                    <div className='absolute inset-0 flex items-end justify-left'>
                        <div className='text-left p-5'>
                            <p className='text-[16px] text-white'>{t("Abdulrahman Almuhanna")}</p>
                            <p className='text-[16px] text-white'>{t("Chairman & Managing Partner")}</p>
                        </div>
                    </div>
                </div>

                <div className='relative rounded-lg overflow-hidden'>
                    <img
                        className='w-full h-[500px] object-cover'
                        src={Partner3}
                        alt='Cardbackgroundimage'></img>
                    <div className='absolute inset-0 bg-gradient-to-t from-[#0D6D86] to-transparent opacity-40'></div>
                    <div className='absolute inset-0 flex items-end justify-left'>
                        <div className='text-left p-5'>
                            <p className='text-[16px] text-white'>{t("Piotr Sobolewski")}</p>
                            <p className='text-[16px] text-white'>{t("Co-Founder & CTO")}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LearMore;
