import { Box, Button } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CheckmarkGreen from '../../assets/svg/CheckmarkGreen';
import GlobeIcon from '../../assets/svg/GlobeIcon';

const LanguageChange = () => {
	const [open, setOpen] = useState(false);
	const [selected, setSelected] = useState(0);
	const containerRef = useRef(null);
	const { i18n } = useTranslation();

	useEffect(() => { 
		setSelected(i18n.language === 'en' ? 0 : 1);
	}, []);

	const languages = [
		{
			code: 'en',
			name: 'English',
			dir: 'ltr',
			country_code: 'gb',
		},
		{
			code: 'ar',
			name: 'العربية',
			dir: 'rtl',
			country_code: 'sa',
		},
	];

	// each list item style
	const listItemStyle = {
		position: 'relative',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		gap: '12px',
		fontWeight: '600',
		cursor: 'pointer',
	};

	// function to close the popup after 100ms
	async function selectHandler(index, lang) {
		// return if same item is selected
		if (index === selected) {
			setOpen(false);
			return;
		}

		setSelected(index);

		setTimeout(() => {
      i18n?.changeLanguage(lang);

      // set direction
      if (lang === 'ar') {
        document.dir = 'rtl';
      } else {
        document.dir = 'ltr';
      }
			setOpen(false);
		}, 10);
	}

	return (
		<Box
			sx={{
				position: 'relative',
				'@media (max-width: 1024px)': {
					marginRight: '10px',
				},
			}}
		>
			{/* toggler */}
			<Button
				onClick={() => setOpen((old) => !old)}
				type="button"
				style={{
					display: 'flex',
					alignItems: 'center',
					gap: '2px',
					cursor: 'pointer',
					borderRadius: '50%',
					height: '60px',
					width: '60px',
				}}
			>
        <GlobeIcon />
				<Box
					component="span"
					sx={{
						transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
						transition: 'all 0.2s',
					}}
				>
					<svg
						width={10}
						height={10}
						viewBox="0 0 10 10"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path d="M2 4L5 7L8 4" stroke="#214E92" strokeWidth="1.5" />
					</svg>
				</Box>
			</Button>
			{/* dropdown */}
			<Box
				ref={containerRef}
				sx={{
					background: 'white',
					display: 'flex',
					flexDirection: 'column',
					gap: '2px',
					position: 'absolute',
					top: { md: '72%', xs: '72%', lg: '100%' },
					right: i18n.language === 'en' ? '0' : 'unset',
					left: i18n.language === 'en' ? 'unset' : '0',
					borderRadius: '6px',
					padding: { lg: '20px 20px', md: '3px 20px', xs: '3px 20px' },
					minWidth: '174px',
					boxShadow: '-6px 6px 44px 0px rgba(70, 74, 91, 0.06)',
					zIndex: '10',
					opacity: open ? '100' : '0',
					visibility: open ? 'visible' : 'hidden',
					transition: 'all 0.2s',
				}}
			>
				{languages?.map((language, index) => (
					<Button
						key={language.code}
						type="button"
						style={{ ...listItemStyle, color: selected === 0 ? '#00807F' : '#8E9AA6' }}
						onClick={() => selectHandler(index, language.code)}
					>
						{selected === index && (
							<span
								style={{
									position: 'absolute',
									left: '8px',
									top: '10px',
								}}
							>
								<CheckmarkGreen />
							</span>
						)}
						<span>
							{' '}
							<p style={{ textTransform: 'capitalize' }}>{language?.name}</p>{' '}
						</span>
					</Button>
				))}
			</Box>
		</Box>
	);
};

export default LanguageChange;
