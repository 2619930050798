import React from 'react';

import Button from '../atoms/Button';
import heroBanner from '../../assets/images/hero-banner1.png';
import { WEBAPP_URL } from '../../config';
import { useTranslation } from 'react-i18next';

const HeroSection = ({ id = '' }) => {
  const { t } = useTranslation();
  return (
    <div className='bg-snowWhite py-10 md:py-0' id={id}>
      <div className='container px-4 mx-auto grid grid-cols-1 md:grid-cols-2 items-center'>
        <div className='text-start md:pl-24 rtl:md:pr-24'>
          <h6 className=' text-action text-xs font-bold tracking-widest'>{t("WITH OPUS")}</h6>

          <h3 className='text-darkBlack font-bold text-2xl lg:text-4xl leading-10 capitalize mt-[5px]'>
            {t("Find the right Candidate that fits your Job with Our App")}
          </h3>

          <p className='text-whiteGray text-lg leading-[18px] mt-5'>
            {t("Create a free account, complete your profile, and get matched with your dream job.")}
          </p>

          <div className='flex xs:justify-center  gap-1 sm:gap-3 md:gap-3 mt-8'>
            <a href={WEBAPP_URL} rel="noreferrer" target='_blank' className=''>
              <Button title={t('Get Started')} className='bg-darkBlue text-snowWhite w-[140px]' />
            </a>

            <a href='/#contact-us' className=''>
              <Button title={t('Contact Us')} className='bg-darkBlue/5 text-darkBlue w-[140px]' />
            </a>
          </div>
        </div>

        <div>
          <img src={heroBanner} alt='hero_banner' />
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
