export const CONTACT_WHATSAPP_NUMBER = "+48509952312";
export const CONTACT_EMAIL = "info@opus.sa";
export const FACEBOOK_URL = null;
export const INSTAGRAM_URL = null;
export const TWITTER_URL = "https://x.com";
export const APPLE_STORE_URL = "https://apps.apple.com/pl/app/opus-employer/id6470737573";
export const GOOGLE_PLAY_URL = "https://play.google.com/store/apps/details?id=com.ewn.opus";
export const WEBAPP_URL = process.env.REACT_APP_URL || "https://app.opus.sa/";

// Feature flags
export const FF_SHOW_COUNTER = false;
