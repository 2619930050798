const CheckmarkGreen = () => {
	return (
		<svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g id="fi:check">
				<path
					id="Vector"
					d="M13.3333 4L5.99996 11.3333L2.66663 8"
					stroke="#97D700"
					strokeWidth={2}
					strokeLinecap="square"
					strokeLinejoin="round"
				/>
			</g>
		</svg>
	);
};

export default CheckmarkGreen;
